<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <!-- 商品検索エリア（モーダル共通） -->
          <product-search-area />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ProductSearchArea from "../components/ProductSearchArea.vue";

export default {
  name: "ProductSearch",

  components: {
    ProductSearchArea,
  },
  data: () => ({
  }),
  computed: {},
  methods: {
  },
};
</script>